import React from 'react';

export default function Layout(props) {
  // const selectedClass = 'Dropdown__Item--selected';
  return(
    <>
      <script src="https://sdk.scdn.co/spotify-player.js"></script>
      <div className="Layout">
        {props.children}
      </div>
    </>
  )
}